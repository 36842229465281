.scrollbar::-webkit-scrollbar {
  width: 12px;
  height: 20px;
}

.scrollbar::-webkit-scrollbar-track {
  background: #f2f2f2;
}

.scrollbar::-webkit-scrollbar-track:hover {
  background: #f2f2f2;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 20px;
  border: 3px solid #f6f7ed;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}
