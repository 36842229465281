@media (max-width: 767px) {
  .center-gradient {
    display: none;
  }
}

@media (min-width: 768px) {
  .center-gradient {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px; /* Increased gap for more spacing */
    padding: 60px; /* Increased padding */
    width: 50%; /* Ensure full width */
    height: 100vh; /* Full viewport height */
  }
}

.center-gradient {
  position: relative;
  overflow: hidden;
}
