.slick-track {
  display: flex !important;
  gap: 1rem; /* Adjust the gap as needed */
}

.slick-slide {
}
@media (min-width: 1024px) {
  .slick-track {
    padding: 0 1rem;
  }

  .slick-slide {
    margin: 0 0.5rem;
  }
}

.slick-dots li button::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #d9d9d9;
  opacity: 1;
}

.slick-dots li.slick-active button::before {
  background-color: #ee5222; /* Change to your desired active color */
}

.custom-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #d9d9d9;
}

.slick-dots {
  display: flex;
  justify-content: center; /* Center dots horizontally */
  align-items: center; /* Center dots vertically */
  margin-bottom: -25px; /* Adjust the position of the dots if necessary */
}

.slick-dots li {
  margin: 0 5px; /* Adjust the spacing between dots if necessary */
}
