.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 50; /* High z-index to ensure it covers other content */
  overflow-y: auto;
  box-sizing: box-content;
  border: 1px solid gray;
}

/* Modal content styling */
.content {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 51; /* Higher than overlay to ensure it is above the overlay */
  overflow-y: auto;
  box-sizing: border-box;
}
